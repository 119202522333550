import { inject } from '@vercel/analytics';

export const onInitialClientRender = () => {

    inject();
    
    console.log("AdRoll script is being injected...");
  
    const script = document.createElement("script");
    script.innerHTML = `
      var adroll_adv_id = "PLVUT7Z2WZD5PJA3EFT66F";
      var adroll_pix_id = "QRDYGGS6QZHLVJW3SW5NX4";
      var adroll_version = "2.0";
      (function(w, d, e, o, a) {
          w.__adroll_loaded = true;
          w.adroll = w.adroll || [];
          w.adroll.f = [ 'setProperties', 'identify', 'track' ];
          var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
                  + "/roundtrip.js";
          for (a = 0; a < w.adroll.f.length; a++) {
              w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                  return function() {
                      w.adroll.push([ n, arguments ])
                  }
              })(w.adroll.f[a])
          }
          e = d.createElement('script');
          o = d.getElementsByTagName('script')[0];
          e.async = 1;
          e.src = roundtripUrl;
          o.parentNode.insertBefore(e, o);
      })(window, document);
      adroll.track("pageView");
    `;
    
    document.body.appendChild(script);
  };
  
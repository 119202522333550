exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-all-initiatives-index-jsx": () => import("./../../../src/pages/all-initiatives/index.jsx" /* webpackChunkName: "component---src-pages-all-initiatives-index-jsx" */),
  "component---src-pages-application-development-and-architecture-index-jsx": () => import("./../../../src/pages/application-development-and-architecture/index.jsx" /* webpackChunkName: "component---src-pages-application-development-and-architecture-index-jsx" */),
  "component---src-pages-blog-2-index-jsx": () => import("./../../../src/pages/blog-2/index.jsx" /* webpackChunkName: "component---src-pages-blog-2-index-jsx" */),
  "component---src-pages-business-process-automation-index-jsx": () => import("./../../../src/pages/business-process-automation/index.jsx" /* webpackChunkName: "component---src-pages-business-process-automation-index-jsx" */),
  "component---src-pages-citrix-index-jsx": () => import("./../../../src/pages/citrix/index.jsx" /* webpackChunkName: "component---src-pages-citrix-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-data-processing-agreement-index-jsx": () => import("./../../../src/pages/data-processing-agreement/index.jsx" /* webpackChunkName: "component---src-pages-data-processing-agreement-index-jsx" */),
  "component---src-pages-demo-index-jsx": () => import("./../../../src/pages/demo/index.jsx" /* webpackChunkName: "component---src-pages-demo-index-jsx" */),
  "component---src-pages-digitalsuite-index-jsx": () => import("./../../../src/pages/digitalsuite/index.jsx" /* webpackChunkName: "component---src-pages-digitalsuite-index-jsx" */),
  "component---src-pages-enterprise-mobility-index-jsx": () => import("./../../../src/pages/enterprise-mobility/index.jsx" /* webpackChunkName: "component---src-pages-enterprise-mobility-index-jsx" */),
  "component---src-pages-get-a-demo-index-jsx": () => import("./../../../src/pages/get-a-demo/index.jsx" /* webpackChunkName: "component---src-pages-get-a-demo-index-jsx" */),
  "component---src-pages-imprint-index-jsx": () => import("./../../../src/pages/imprint/index.jsx" /* webpackChunkName: "component---src-pages-imprint-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industry-case-studies-index-jsx": () => import("./../../../src/pages/industry-case-studies/index.jsx" /* webpackChunkName: "component---src-pages-industry-case-studies-index-jsx" */),
  "component---src-pages-iot-integration-solutions-index-jsx": () => import("./../../../src/pages/iot-integration-solutions/index.jsx" /* webpackChunkName: "component---src-pages-iot-integration-solutions-index-jsx" */),
  "component---src-pages-leadership-index-jsx": () => import("./../../../src/pages/leadership/index.jsx" /* webpackChunkName: "component---src-pages-leadership-index-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-platform-governance-and-operations-index-jsx": () => import("./../../../src/pages/platform-governance-and-operations/index.jsx" /* webpackChunkName: "component---src-pages-platform-governance-and-operations-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-salesforce-integration-index-jsx": () => import("./../../../src/pages/salesforce-integration/index.jsx" /* webpackChunkName: "component---src-pages-salesforce-integration-index-jsx" */),
  "component---src-pages-sap-integration-index-jsx": () => import("./../../../src/pages/sap-integration/index.jsx" /* webpackChunkName: "component---src-pages-sap-integration-index-jsx" */),
  "component---src-pages-terms-of-use-index-jsx": () => import("./../../../src/pages/terms-of-use/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-jsx" */),
  "component---src-pages-vision-index-jsx": () => import("./../../../src/pages/vision/index.jsx" /* webpackChunkName: "component---src-pages-vision-index-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-post-js": () => import("./../../../src/templates/case-post.js" /* webpackChunkName: "component---src-templates-case-post-js" */),
  "component---src-templates-initiative-post-js": () => import("./../../../src/templates/initiative-post.js" /* webpackChunkName: "component---src-templates-initiative-post-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */)
}

